import styled, { css } from 'styled-components';
import Colors from '../../Colors';

export const FeatureContainer = styled.div`
  margin-top: 47px;
`;

export const FeatureTitle = styled.legend`
  font-size: 20px;
  font-weight: 800;
`;
export const FeatureDivider = styled.div`
  margin: 15px 0;
  border-bottom: solid 1px ${Colors.border};
`;
export const DisclaimersList = styled.ol`
  padding: 32px 0 32px 14px;
`;
export const Disclaimer = styled.li`
  font-size: 12px;
  font-weight: 500;
  color: #404040;
  margin-bottom: 5px;
`;
export const PricePlan = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 28px;
  width: 31%;
  justify-content: space-between;
  @media (max-width: 700px) {
    width: 100%;
  }
  min-height: 241px;
  border-radius: 15px;
  border: solid 2px ${props => (props.disabled ? Colors.border : Colors.primary)};
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    ${props =>
      !props.disabled &&
      css`
        box-shadow: 0 2px 20px 0 rgba(27, 152, 153, 0.19);
      `}
  }
`;
export const PricePlanTitle = styled.legend`
  margin-top: 25px;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
`;
export const PricePlanDesc = styled.p`
  margin: 25px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #777777;
`;
export const PricePlanPrice = styled.p`
  margin: 0 0 28px 0;
  font-size: 28px;
  font-weight: 800;
  text-align: center;
`;
export const LoadingView = styled.div`
  display: flex;
  height: 100vh;
`;
export const RegisterContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.grey};
  height: 100%;
`;
export const TermsLink = styled.a`
  color: ${Colors.black};
`;
