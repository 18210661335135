import { useLazyQuery, useMutation } from '@apollo/client';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { getAllISOCodes } from 'iso-country-currency';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Colors from '../../Colors';
import {
  FormButtonsContainer,
  FormError,
  FormFooterMessage,
  FormHeaderLabel,
  FormHeaderWrapper,
  FormInput,
  FormLabel,
  FormParagraph,
  FormSection,
  FormSelect,
  FormSubmitButton,
  FormWrapper,
  InputGroup,
  InputsWrapper,
  PageCR,
  PageContainer,
  PageFooter,
  PageHeader,
  PageLogo,
  PageTitle
} from '../../components/Shared/Forms/Forms';
import { FormDivider } from '../../components/Shared/Shared';
import PageLoader from '../../components/Shared/Spinner';
import useIcons from '../../hooks/useIcons';
import { GetProviderByCompleteRegistrationToken, UpdateProviderByCompleteRegistrationToken } from '../../queries';
import { isEmail, isValidUUID } from '../../utils/validators';
import { BUS_USER_ROLES } from '../Bookings/types';
import ConfirmationView from './ConfirmationView';
import FeaturesList from './FeaturesList';
import { LoadingView, PricePlan, PricePlanDesc, PricePlanTitle, TermsLink } from './styled';

const sortCountries = (a, b) => {
  // put UK, Ireland, Australia, US, Canada at the top, else alphabetical
  const topCountries = ['GB', 'IE', 'AU', 'US', 'CA'];
  const aIndex = topCountries.indexOf(a.iso);
  const bIndex = topCountries.indexOf(b.iso);
  if (aIndex !== -1 && bIndex !== -1) {
    return aIndex - bIndex;
  }
  if (aIndex !== -1) {
    return -1;
  }
  if (bIndex !== -1) {
    return 1;
  }
  return a.countryName.localeCompare(b.countryName);
};
const countries = getAllISOCodes()
  .sort(sortCountries)
  .filter(country => {
    return country.countryName !== 'Israel';
  });

const countriesNames = countries.map(country => country.countryName);
const currencies = countries.reduce((acc, country) => {
  return {
    ...acc,
    [country.iso]: country.currency
  };
}, {});

const RegisterForm = ({ onSubmit, loading, provider, onSubmitLoading, updatedProvider, onSubmitError }) => {
  const icons = useIcons();
  // const [rates, setRates] = useState({});
  const { control, handleSubmit, errors, watch } = useForm();
  const onChange = args => ({ value: args[0].target.value });
  const handleOnSubmit = handleSubmit(onSubmit);

  const currency = watch('currency', provider?.payment?.currency || 'GBP');

  let termsAndFees = [
    `1 Branch`,
    `12-month Agreement`,
    `1X Business User Licenses`,
    `3 Months Notice Period upon Cancellation`,
    `5.5% + 20p Service Fee / Transaction (Domestic Cards Only)`,
    `${currency}25 / Extra User License / Month`
  ];

  if (provider?.payment?.plan === `FLEXI`) {
    termsAndFees = [
      `1 Branch`,
      `12-month Agreement`,
      `1X Business User Licenses`,
      `3 Months Notice Period upon Cancellation`,
      `5.5% + 20p Service Fee / Transaction (Domestic Cards Only)`,
      `${currency}25 / Extra User License / Month`
    ];
  } else if (provider?.payment?.plan === `ESSENTIAL`) {
    termsAndFees = [
      `1 Branch`,
      `12-month Agreement`,
      `1X Business User Licenses`,
      `3 Months Notice Period upon Cancellation`,
      `3.5% + 20p Service Fee / Transaction (Domestic Cards Only)`,
      `${currency}16 / Extra User License / Month`
    ];
  } else if (provider?.payment?.plan === `PREMIUM`) {
    termsAndFees = [
      `2 Branches`,
      `12-month Agreement`,
      `3X Business User Licenses`,
      `3 Months Notice Period upon Cancellation`,
      `2.5% + 20p Service Fee / Transaction (Domestic Cards Only)`,
      `${currency}14 / Extra User License / Month`
    ];
  } else if (provider?.payment?.plan === `ENTERPRISE`) {
    termsAndFees = [
      `3 Branches`,
      `12-month Agreement`,
      `6X Business User Licenses`,
      `3 Months Notice Period upon Cancellation`,
      `1.9% + 20p Service Fee / Transaction (Domestic Cards Only)`,
      `${currency}12 / Extra User License / Month`
    ];
  }

  if (provider?.payment?.terms?.branches_count) {
    const { branches_count, agreement_length, business_user_licenses, notice_period, extra_user_license_fee, extras = {} } = provider?.payment?.terms || {};
    const { fee_percentage, fee_fixed } = provider?.payment;
    const monthlyFees = provider?.payment?.monthly_fees || 'Undisclosed';
    const yearlyFees = provider?.payment?.yearly_fees;
    termsAndFees = [
      `${branches_count} Branches`,
      yearlyFees ? `Yearly Fees: ${currency}${yearlyFees}` : `Monthly Fees: ${currency}${monthlyFees}`,
      `${agreement_length}-month Agreement`,
      `${business_user_licenses}X Business User Licenses`,
      `${notice_period} Months Notice Period upon Cancellation`,
      `${fee_percentage}% + ${currency}${fee_fixed} Service Fee / Transaction (Domestic Cards Only)`,
      `${currency}${extra_user_license_fee} / Extra User License / Month`,
      ...extras
    ];
  }

  if (updatedProvider) {
    return <ConfirmationView />;
  }

  return (
    <PageContainer style={{ height: '100vh' }}>
      <PageHeader>
        <PageLogo alt={'Collar Logo'} image={icons.logo.childImageSharp.gatsbyImageData} />
        <PageTitle>Registration Order Form</PageTitle>
      </PageHeader>
      {loading || !provider ? (
        <LoadingView>
          <PageLoader size={50} />
        </LoadingView>
      ) : (
        <FormWrapper maxWidth={1000}>
          <FormParagraph>Please fill in all the details below as all fields are required.</FormParagraph>
          <FormDivider />
          <FormSection paddingX={32}>
            <InputGroup>
              <FormLabel>Provider ID</FormLabel>
              <FormInput disabled value={provider?.id?.toUpperCase()} />
            </InputGroup>
          </FormSection>
          <FormDivider />
          <FormSection paddingX={32}>
            <FormHeaderWrapper>
              <FormHeaderLabel>Company Details</FormHeaderLabel>
            </FormHeaderWrapper>
            <InputsWrapper>
              <InputGroup>
                <FormLabel error={errors.providerName}>Company Name</FormLabel>
                <Controller
                  as={<FormInput error={errors.providerName} type={'text'} />}
                  control={control}
                  name={'providerName'}
                  onChange={onChange}
                  defaultValue={provider?.name}
                  rules={{ required: true }}
                />
                {errors.providerName && <FormError>{errors.providerName.message || 'Company Name is required'}</FormError>}
              </InputGroup>
              <InputGroup>
                <FormLabel error={errors.providerRegistrationNumber}>Company Registration Number</FormLabel>
                <Controller
                  as={<FormInput error={errors.providerRegistrationNumber} type={'text'} />}
                  control={control}
                  name={'providerRegistrationNumber'}
                  onChange={onChange}
                  defaultValue={provider?.registration_number}
                  rules={{ required: true }}
                />
                {errors.providerRegistrationNumber && <FormError>{errors.providerRegistrationNumber.message || 'Company Registration Number is required'}</FormError>}
              </InputGroup>
            </InputsWrapper>
          </FormSection>
          <FormDivider />
          <FormSection paddingX={32}>
            <FormHeaderWrapper>
              <FormHeaderLabel>Company Address</FormHeaderLabel>
            </FormHeaderWrapper>
            <InputsWrapper>
              <InputGroup>
                <FormLabel error={errors.providerAddressLineOne}>Address Line 1</FormLabel>
                <Controller
                  as={<FormInput error={errors.providerAddressLineOne} type={'text'} />}
                  control={control}
                  name={'providerAddressLineOne'}
                  onChange={onChange}
                  defaultValue={provider?.address?.line1}
                  rules={{ required: true }}
                />
                {errors.providerAddressLineOne && <FormError>{errors.providerAddressLineOne.message || 'Address Line 1 is required'}</FormError>}
              </InputGroup>
              <InputGroup>
                <FormLabel error={errors.providerAddressLineTwo}>Address Line 2</FormLabel>
                <Controller
                  as={<FormInput error={errors.providerAddressLineTwo} type={'text'} />}
                  control={control}
                  name={'providerAddressLineTwo'}
                  onChange={onChange}
                  defaultValue={provider?.address?.line2 || ''}
                  rules={{ required: true }}
                />
                {errors.providerAddressLineTwo && <FormError>{errors.providerAddressLineTwo.message || 'Address Line 2 is required'}</FormError>}
              </InputGroup>

              <InputGroup>
                <FormLabel error={errors.city}>City</FormLabel>
                <Controller
                  as={<FormInput error={errors.city} type={'text'} />}
                  control={control}
                  name={'city'}
                  onChange={onChange}
                  defaultValue={provider?.address?.city}
                  rules={{ required: true }}
                />
                {errors.city && <FormError>{errors.city.message || 'City is required'}</FormError>}
              </InputGroup>

              <InputGroup>
                <FormLabel error={errors.postcode}>Postcode/Eircode</FormLabel>
                <Controller
                  as={<FormInput error={errors.postcode} type={'text'} />}
                  control={control}
                  name={'postcode'}
                  onChange={onChange}
                  defaultValue={provider?.address?.postcode}
                  rules={{ required: false }}
                />
                {errors.postcode && <FormError>{errors.postcode.message || 'postcode is required'}</FormError>}
              </InputGroup>

              <InputGroup>
                <FormLabel error={errors.country}>Country</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect
                      height={48}
                      fontSize={16}
                      name={'country'}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value || ''}
                      disabled
                    >
                      {countriesNames.map(c => (
                        <option value={c}>{c}</option>
                      ))}
                    </FormSelect>
                  )}
                  control={control}
                  name={'country'}
                  rules={{ required: true }}
                  defaultValue={provider?.address?.country || 'United Kingdom'}
                />
                {errors.country && <FormError>{errors.country.message || 'Country is required'}</FormError>}
              </InputGroup>

              <InputGroup>
                <FormLabel error={errors.currency}>Currency</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect
                      height={48}
                      fontSize={16}
                      name={'currency'}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value || ''}
                      disabled
                    >
                      {Object.values(currencies).map(c => (
                        <option value={c}>{c}</option>
                      ))}
                    </FormSelect>
                  )}
                  control={control}
                  name={'currency'}
                  rules={{ required: true }}
                  defaultValue={provider?.payment?.currency || 'GBP'}
                />
                {errors.currency && <FormError>{errors.currency.message || 'currency is required'}</FormError>}
              </InputGroup>
            </InputsWrapper>
          </FormSection>
          <FormDivider />
          <FormSection paddingX={32}>
            <FormHeaderWrapper>
              <FormHeaderLabel>Account Owner Details</FormHeaderLabel>
            </FormHeaderWrapper>
            <InputsWrapper>
              <InputGroup>
                <FormLabel error={errors.name}>Full Name</FormLabel>
                <Controller as={<FormInput error={errors.name} type={'text'} />} control={control} name={'name'} onChange={onChange} defaultValue={''} rules={{ required: true }} />
                {errors.name && <FormError>{errors.name.message || 'Full name is required'}</FormError>}
              </InputGroup>

              <InputGroup>
                <FormLabel error={errors.userEmail}>Business Email Address</FormLabel>
                <Controller
                  as={<FormInput error={errors.userEmail} type={'email'} />}
                  control={control}
                  name={'userEmail'}
                  onChange={onChange}
                  defaultValue={''}
                  rules={{ required: true, pattern: isEmail }}
                />
                {errors.userEmail && <FormError>{errors.userEmail.message || 'Business Email Address is required'}</FormError>}
              </InputGroup>

              <InputGroup>
                <FormLabel error={errors.position}>Position</FormLabel>
                <Controller as={<FormInput error={errors.position} type={'text'} />} control={control} name={'position'} onChange={onChange} defaultValue={''} rules={{ required: true }} />
                {errors.position && <FormError>{errors.position.message || 'position is required'}</FormError>}
              </InputGroup>

              <InputGroup>
                <FormLabel error={errors.userContactNumber}>Contact Number</FormLabel>
                <Controller
                  as={<FormInput error={errors.userContactNumber} />}
                  control={control}
                  name={'userContactNumber'}
                  onChange={onChange}
                  defaultValue={provider?.contact_number || ''}
                  rules={{ required: true }}
                />
                {errors.userContactNumber && <FormError>{errors.userContactNumber.message || 'Contact Number is required'}</FormError>}
              </InputGroup>
            </InputsWrapper>
          </FormSection>
          <FormDivider />
          <FormSection paddingX={32}>
            <FormHeaderWrapper>
              <FormHeaderLabel>Plan</FormHeaderLabel>
            </FormHeaderWrapper>
          </FormSection>
          <FormSection flexDirection={'row'} paddingX={32}>
            <PricePlan disabled={provider?.payment?.plan !== 'FLEXI'}>
              <PricePlanTitle>Flexi</PricePlanTitle>
              <PricePlanDesc>Our most flexible pay-as-you-go tier.</PricePlanDesc>
              {/* <PricePlanPrice>Free</PricePlanPrice> */}
            </PricePlan>
            <PricePlan disabled={provider?.payment?.plan !== 'ESSENTIAL'}>
              <PricePlanTitle>Essential</PricePlanTitle>
              <PricePlanDesc>For small businesses with limited small-scale operations.</PricePlanDesc>
              {/* <PricePlanPrice>
                {currency}
                {convertGBPToCurrency(49.99, currency)}
              </PricePlanPrice> */}
            </PricePlan>
            <PricePlan disabled={provider?.payment?.plan !== 'PREMIUM'}>
              <PricePlanTitle>Premium</PricePlanTitle>
              <PricePlanDesc>For businesses with hybrid operations involving multiple members and functions.</PricePlanDesc>
              {/* <PricePlanPrice>
                {currency}
                {convertGBPToCurrency(99.99, currency)}
              </PricePlanPrice> */}
            </PricePlan>
            <PricePlan disabled={provider?.payment?.plan !== 'ENTERPRISE'}>
              <PricePlanTitle>Business</PricePlanTitle>
              <PricePlanDesc>For large businesses with multi-site complex operations requiring bespoke module customisation.</PricePlanDesc>
              {/* <PricePlanPrice>
                {currency}
                {convertGBPToCurrency(199.99, currency)}
              </PricePlanPrice> */}
            </PricePlan>
          </FormSection>
          <FormSection bgColor={Colors.grey} paddingX={32}>
            {/* <FeaturesList
              title={'Platform Features'}
              features={[
                'Branded Store on Collar App',
                'Appointment Booking Module',
                'Subscriptions Module',
                'E-commerce Module',
                'Chat Module',
                'Multi-Service Pet Module (1)',
                'Web Registration & Booking Module (2)',
                'Dedicated Account Support'
              ]}
            />
            <FeaturesList
              title={'Payment Processing'}
              features={[
                'Support for In-App Payment',
                'Support for 3DS Authenticated Payment Methods',
                'Provider-Initiated Payment Requests',
                'Payment Amount Adjustment',
                'Payment Dispute & Refund Handling',
                'Fraud Analysis & Protection',
                'Direct Payments to Bank Account (Every 7 Working Days)',
                'Automated Deffered Monthly Billings'
              ]}
            />
            <FeaturesList title={'Account Management'} features={['Custom Permissions per User', 'Multi-Branch Management']} />
            <FeaturesList title={'Account Support'} features={['9-8 Account Support', 'Training Webinars', 'On-Demand Account Reviews', 'Quarterly Service Analytics Report']} /> */}
            <FeaturesList title={'Terms & Fees'} features={termsAndFees} />
            {/* <DisclaimersList>
              <Disclaimer>Access to the pet module is granted on a case by case basis subject to the nature of the business.</Disclaimer>
              <Disclaimer>Payment Amount Adjustment is avaialble to service-based orders and is granted on a case by case basis.</Disclaimer>
            </DisclaimersList> */}
          </FormSection>
          <FormSection paddingY={56} paddingX={32} alignItems={'center'}>
            <FormFooterMessage>
              By accepting, I warrant that I have all necessary capacity and authority to enter into The Agreement, comprised of this Order Form and the{' '}
              <TermsLink href={'https://collar.pet/provider-terms'} target={'_blank'}>
                Terms and Conditions
              </TermsLink>
              , on behalf of the above listed company.
            </FormFooterMessage>
            <FormButtonsContainer>
              <FormSubmitButton onClick={handleOnSubmit} error={onSubmitError} loading={onSubmitLoading}>
                Accept & Submit
              </FormSubmitButton>
            </FormButtonsContainer>
          </FormSection>
        </FormWrapper>
      )}
      <PageFooter>
        <PageCR>Copyright © {new Date().getFullYear()} Collar. All Rights Reserved</PageCR>
        <PageCR>Collar is a trademark of Collar Ltd. Company no.13304038. Registered in England & Wales.</PageCR>
      </PageFooter>
    </PageContainer>
  );
};

const RegisterView = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [_, providerId] = isValidUUID(urlParams.get('providerId') || '') || [''];
  const token = urlParams.get('token');

  const [getProvider, { data: { getProviderByCompleteRegistrationToken: provider } = {}, loading, error }] = useLazyQuery(GetProviderByCompleteRegistrationToken, { fetchPolicy: 'network-only' });

  const [updateProvider, { data: updatedProvider, loading: onSubmitLoading, error: onSubmitError }] = useMutation(UpdateProviderByCompleteRegistrationToken, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (!providerId || !token) {
      navigate('/login');
      return;
    }
    getProvider({ variables: { providerId, token } });
  }, [providerId]);

  useEffect(() => {
    if (error) {
      navigate('/login');
      return;
    }
  }, [error]);

  useEffect(() => {
    if (updatedProvider) {
      window?.history?.replaceState?.({}, 'Confirmed', '/confirmed');
    }
  }, [updatedProvider]);

  const onSubmit = form => {
    updateProvider({
      variables: {
        token,
        BusUser: {
          name: form.name,
          email: form.userEmail,
          role: BUS_USER_ROLES.SUPER_ADMIN,
          position: form.position,
          contact_number: form.userContactNumber
        },
        Provider: {
          id: providerId,
          name: form.providerName,
          registration_number: form.providerRegistrationNumber,
          address: {
            line1: form.providerAddressLineOne,
            line2: form.providerAddressLineTwo,
            city: form.city,
            country: form.country,
            postcode: form.postcode
          }
        }
      },
      fetchPolicy: 'no-cache'
    });
  };

  return <RegisterForm onSubmit={onSubmit} loading={loading} onSubmitError={onSubmitError} onSubmitLoading={onSubmitLoading} provider={provider} updatedProvider={updatedProvider} />;
};

export default RegisterView;
