import React from 'react';
import { Col, Container, Row } from '../../components/Shared/Shared';
import { FeatureContainer, FeatureTitle, FeatureDivider } from './styled';

const FeaturesList = ({ title, features }) => {
  return (
    <FeatureContainer>
      <FeatureTitle>{title}</FeatureTitle>
      <FeatureDivider />
      <Container>
        <Row>
          {features &&
            features.map((feature, idx) => (
              <Col key={idx} size={6} lg={12} md={12}>
                <div>{feature}</div>
                <FeatureDivider />
              </Col>
            ))}
        </Row>
      </Container>
    </FeatureContainer>
  );
};

export default FeaturesList;
