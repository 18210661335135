import React from 'react';
import { FormWrapper, FormSection, PageContainer, PageFooter, PageCR, ConfirmationContainer, ConfirmationTitle, ConfirmationMessage, ConfirmationLogo } from '../../components/Shared/Forms/Forms';
import useIcons from '../../hooks/useIcons';

export default function Confirmation() {
  const icons = useIcons();
  return (
    <PageContainer>
      <ConfirmationContainer>
        <FormWrapper maxWidth={1000} height={'75vh'} width={'1000px'} display={'flex'}>
          <FormSection paddingX={32} alignItems={'center'}>
            <ConfirmationLogo alt={'Logo'} image={icons.logoLarge.childImageSharp.gatsbyImageData} />
            <ConfirmationTitle>Thank You!</ConfirmationTitle>
            <ConfirmationMessage>Your information is successfully submitted. Once confirmed, you will receive an email with a link to setup your payment account with Stripe.</ConfirmationMessage>
          </FormSection>
        </FormWrapper>
        <PageFooter>
          <PageCR>Copyright © {new Date().getFullYear()} Collar. All Rights Reserved</PageCR>
          <PageCR>Collar is a trademark of Layers Ltd. Company no.8763949. Registered in England & Wales.</PageCR>
        </PageFooter>
      </ConfirmationContainer>
    </PageContainer>
  );
}
